<template>
  <div>
    <iframe
      :src='frame'
      width='100%'
      height='1200px'
      frameborder='0'
    ></iframe>
  </div>
</template>

<script>
import { ref } from '@vue/composition-api'
import { jsPDF } from 'jspdf'
import { useRouter } from '@core/utils'
import prints from '@/api/prints'
import { i18n } from '@/plugins/i18n'
import font from '@/assets/fonts/font.json'
import fontBold from '@/assets/fonts/fontBold.json'
import {
  sumdatetime, sumdateUpper,
} from '@/plugins/filters'
import 'jspdf-autotable'
import shop from '@/api/systemSetting/shop'
import booze_data from '@/@fake-db/data/booze_data.json'
import smoking_data from '@/@fake-db/data/smoking_data.json'

export default {
  setup() {
    const moment = require('moment')
    const { route } = useRouter()
    const fonts = ref(font)
    const fontsBold = ref(fontBold)
    const dataTableList = ref([])
    const fontTitle = ref(13)
    const fontBody = ref(12)
    const shop_print_type = ref(0)
    const boozeList = ref(booze_data.data)
    const smokingList = ref(smoking_data.data)
    const shopData = ref({})
    const frame = ref('')
    const countPage = ref(0)

    shop.shopGet().then(res => {
      shop_print_type.value = route.value.query.shop_print_type
      shopData.value = res
      printsOpdCard()
    })
    const printsOpdCard = () => {
      prints.printsOpdCard({
        opd_id: route.value.query.id.split(','),
      }).then(res => {
        dataTableList.value = res.map(item => ({
          ...item,
          opd_cc: item.opd_cc.split('\n').join(''),
          opd_hpi: item.opd_hpi.split('\n').join(''),
          opd_pmh: item.opd_pmh.split('\n').join(''),
          date_now: `${`( ${i18n.t('print_date')} : ${sumdatetime(
            moment().format(''),
            i18n.locale,
          )} )`}`,
          date_now2: `${i18n.t('date')} ${sumdateUpper(item.opd_date, i18n.locale)}`,
          customer_age: `${i18n.t('age')} ${item.customer_age}`,
          check_list: item.check_list || [],
          course_list: item.course_list || [],
          drug_list: item.drug_list || [],
        }))
        savePDF(dataTableList.value)
      })
    }

    const savePDF = data => {
      const chkAdd = false
      const doc = new jsPDF('l', 'mm', 'a5')
      doc.addFileToVFS('TH-Niramit-AS-bold.ttf', fonts.value.data)
      doc.addFileToVFS('TH-Niramit-AS-Bold-bold.ttf', fontsBold.value.data)
      doc.addFont('TH-Niramit-AS-bold.ttf', 'TH-Niramit', 'bold')
      doc.addFont('TH-Niramit-AS-Bold-bold.ttf', 'TH-Niramit', 'bold')
      doc.setFont('TH-Niramit', 'bold')
      doc.setTextColor('#000')
      doc.setProperties({
        title: `OPD CARD - HN ${data[0].customer_id}`,
      })
      for (let a = 0; a < data.length; a++) {
        const spacer = 4
        let totalL = 5
        let totalR = 5
        const starterL = 5
        const starterR = 115
        const starterRHeader = 205

        doc.autoTable({
          didDrawPage(hookData) {
            const arrLeft = [
              {
                name: i18n.t('name'),
                value: `${data[a].customer_fullname} HN: ${data[a].customer_id.replaceAll('\r', '')} VN: ${data[a].opd_code}`,
              },
              {
                name: i18n.t('gender'),
                value: `${data[a].customer_gender} ${i18n.t('age')} : ${data[a].customer_age} ${i18n.t('blood_type')} : ${data[a].customer_blood}`,
              },
              {
                name: i18n.t('telephone_number'),
                value: `${data[a].customer_tel} ${i18n.t('congenital_disease')} : ${data[a].customer_disease} ${i18n.t('be_allergic')} : ${data[a].customer_allergic}`,
              },
              { name: i18n.t('address'), value: `${data[a].customer_address}` },

            ]
            const arrRight = [
              { name: '', value: `${data[a].date_now}` },
              { name: '', value: `${data[a].date_now2}` },
              { name: i18n.t('license_number'), value: `${data[a].shop_license}` },
              { name: i18n.t('nursing_address'), value: `${data[a].shop_address}` },
              { name: i18n.t('telephone_number'), value: `${data[a].shop_tel}` },
            ]
            const arrCheck1 = [
              { name: 'T: ', value: `${data[a].opd_t}`, unit: 'C.' },
              { name: 'P: ', value: `${data[a].opd_p}`, unit: '/min.' },
              { name: 'RR: ', value: `${data[a].opd_rr}`, unit: '/min.' },
              { name: 'BP: ', value: `${data[a].opd_bp}`, unit: 'mmHg' },
            ]
            const arrCheck2 = [
              { name: i18n.t('weight'), value: `${data[a].opd_w}`, unit: i18n.t('kg') },
              { name: i18n.t('height'), value: `${data[a].opd_h}`, unit: i18n.t('cm') },
              { name: 'BMI: ', value: `${data[a].opd_bmi}`, unit: '' },
              { name: 'FBS: ', value: `${data[a].opd_fbs}`, unit: 'mg/dl' },
            ]

            hookData.doc.setFont('TH-Niramit', 'bold').setFontSize(fontTitle.value)
            hookData.doc.text(`${i18n.t('patient_treatment_certificate')} ${data[a].shop_name} ${data[a].shop_nature}`, starterL, totalL)
            totalL += spacer
            hookData.doc.setFont('TH-Niramit', 'bold').setFontSize(fontBody.value)
            arrLeft.forEach(item => {
              const textList = hookData.doc.setFont('TH-Niramit', 'bold').setFontSize(fontBody.value).splitTextToSize(`${item.name} ${item.value}`, 100)
              textList.forEach(element => {
                hookData.doc.text(element, starterL, totalL)
                totalL += spacer
              })
            })

            arrRight.forEach(item => {
              const textList = hookData.doc.setFont('TH-Niramit', 'bold').setFontSize(fontBody.value).splitTextToSize(`${item.name} ${item.name == '' ? '' : ''} ${item.value}`, 100)
              textList.forEach(element => {
                hookData.doc.text(element, starterRHeader, totalR, null, null, 'right')
                totalR += spacer
              })
            })

            if (totalL > totalR) {
              totalR = totalL
            } else {
              totalL = totalR
            }
            totalL -= 2.5
            hookData.doc.setLineWidth(0.5)
            hookData.doc.setDrawColor('#000')
            hookData.doc.line(starterL, totalL, starterRHeader, totalL)

            // hookData.doc.setDrawColor('#000')
            // hookData.doc.line(61, totalL, starterR, totalL)
            totalL += spacer
            hookData.doc.text(`${i18n.t('general_information')} (${i18n.t('history_washing_by')} : ${data[a].user_fullname})`, starterL, totalL)
            hookData.doc.setLineWidth(0.1)
            hookData.doc.setDrawColor('#000')
            hookData.doc.line(starterL, totalL + 0.5, 64, totalL + 0.5)

            // hookData.doc.line(starterL, totalL + 1, 64, totalL + 1)
            totalL += spacer
            let spacerRow1 = 5
            arrCheck1.forEach(item => {
              hookData.doc.text(`${item.name} ${item.value} ${item.unit}`, spacerRow1, totalL)
              spacerRow1 += 27
            })
            totalL += spacer
            let spacerRow2 = 5
            arrCheck2.forEach(item => {
              hookData.doc.text(`${item.name} ${item.value} ${item.unit}`, spacerRow2, totalL)
              spacerRow2 += 27
            })
            totalL += spacer
            hookData.doc.text(`${i18n.t('important_symptoms_current_history')}`, starterL, totalL)
            hookData.doc.line(starterL, totalL + 0.5, 65, totalL + 0.5)

            // hookData.doc.line(starterL, totalL + 1, 65, totalL + 1)
            totalL += spacer
            hookData.doc.text(`${i18n.t('smoking')}: `, starterL, totalL)
            if (data[a].opd_fag) {
              hookData.doc.text(`${smokingList.value[+data[a].opd_fag - 1].th}`, starterL + 13, totalL)
            }

            hookData.doc.text(`${i18n.t('drink_alcohol')}: `, starterL + 50, totalL)
            if (data[a].opd_alcohol) {
              hookData.doc.text(`${boozeList.value[+data[a].opd_alcohol - 1].th}`, starterL + 63, totalL)
            }
            totalL += spacer

            hookData.doc.text('CC: ', starterL, totalL)
            const cc = hookData.doc.setFont('TH-Niramit', 'bold').setFontSize(fontBody.value).splitTextToSize(data[a].opd_cc, 100)
            cc.forEach(el => {
              hookData.doc.text(el, starterL + 6, totalL)
              totalL += spacer
            })

            hookData.doc.text('HPI: ', starterL, totalL)
            const hpi = hookData.doc.setFont('TH-Niramit', 'bold').setFontSize(fontBody.value).splitTextToSize(data[a].opd_hpi, 100)
            hpi.forEach(el => {
              hookData.doc.text(el, starterL + 6, totalL)
              totalL += spacer
            })

            hookData.doc.text('PMH: ', starterL, totalL)
            const pmh = hookData.doc.setFont('TH-Niramit', 'bold').setFontSize(fontBody.value).splitTextToSize(data[a].opd_pmh, 100)
            pmh.forEach(el => {
              hookData.doc.text(el, starterL + 6, totalL)
              totalL += spacer
            })

            hookData.doc.text(i18n.t('physical_examination'), starterL, totalL)
            hookData.doc.line(starterL, totalL + 0.5, 28, totalL + 0.5)

            // hookData.doc.line(starterL, totalL + 1, 28, totalL + 1)
            totalL += spacer

            hookData.doc.text('PE: ', starterL, totalL)
            const pe = hookData.doc.setFont('TH-Niramit', 'bold').setFontSize(fontBody.value).splitTextToSize(data[a].opd_pe, 100)
            pe.forEach(el => {
              hookData.doc.text(el, starterL + 6, totalL)
              totalL += spacer
            })

            hookData.doc.text('GA: ', starterL, totalL)
            const ga = hookData.doc.setFont('TH-Niramit', 'bold').setFontSize(fontBody.value).splitTextToSize(data[a].opd_ga, 100)
            ga.forEach(el => {
              hookData.doc.text(el, starterL + 6, totalL)
              totalL += spacer
            })

            hookData.doc.text(`${i18n.t('diagnose')}: `, starterL, totalL)
            hookData.doc.line(starterL, totalL + 0.5, 26, totalL + 0.5)
            totalL += spacer

            hookData.doc.text('Dx: ', starterL, totalL)
            const dx = hookData.doc.setFont('TH-Niramit', 'bold').setFontSize(fontBody.value).splitTextToSize(data[a].opd_dx, 100)
            dx.forEach(el => {
              hookData.doc.text(el, starterL + 6, totalL)
              totalL += spacer
            })
            hookData.doc.text(`${i18n.t('note')}`, starterL, totalL)
            hookData.doc.line(starterL, totalL + 0.5, 18, totalL + 0.5)
            const note = hookData.doc.setFont('TH-Niramit', 'bold').setFontSize(fontBody.value).splitTextToSize(data[a].opd_comment
                .replaceAll('<p>', '')
                .replaceAll('<&nbsp;>', ' ')
                .replaceAll('<span>', '')
                .replaceAll('</span>', '')
                .replaceAll('</p>', '')
                .replaceAll('<br>', '\n')
                .replaceAll('</br>', '')
                .replaceAll('<li>', '- ')
                .replaceAll('</li>', '\n')
                .replaceAll('<ul>', '')
                .replaceAll('</ul>', '')
                .replaceAll('<ol>', '')
                .replaceAll('</ol>', ''),
              93)
            note.forEach((element, index) => {
              hookData.doc.text(element, index == 0 ? starterL + 14 : starterL + 3, totalL)
              totalL += spacer
            })
            hookData.doc.setLineWidth(0.5)
            hookData.doc.setDrawColor('#000')
            hookData.doc.line(113, totalR - 2.5, 113, 140)

            /// Start Side bar Right
            totalR += spacer - 2.5
            hookData.doc.text(`${i18n.t('examination_list')}`, starterR, totalR)
            hookData.doc.setLineWidth(0.1)
            hookData.doc.setDrawColor('#000')
            hookData.doc.line(starterR, totalR + 0.5, starterR + 18, totalR + 0.5)
            totalR += spacer
            data[a].check_list.forEach((item, index) => {
              const list = hookData.doc.setFont('TH-Niramit', 'bold').setFontSize(fontBody.value).splitTextToSize(`${index + 1}) ${item.opdchecking_name}${item.opdchecking_code}`, 90)
              list.forEach(element => {
                hookData.doc.text(element, starterR + 3, totalR)
                totalR += +spacer
              })
            })
            hookData.doc.text(`${i18n.t('treatment_item')}`, starterR, totalR)
            hookData.doc.line(starterR, totalR + 0.5, starterR + 18, totalR + 0.5)
            totalR += spacer
            data[a].course_list.forEach((item, index) => {
              const list = hookData.doc.setFont('TH-Niramit', 'bold').setFontSize(fontBody.value).splitTextToSize(`${index + 1}) ${item.orderdetail_name}`, 90)
              list.forEach(element => {
                hookData.doc.text(element, starterR + 3, totalR)
                totalR += +spacer
              })
            })
            hookData.doc.text(`${i18n.t('list_drug_equipment')}`, starterR, totalR)
            hookData.doc.line(starterR, totalR + 0.5, starterR + 26, totalR + 0.5)
            totalR += +spacer
            data[a].drug_list.forEach((item, index) => {
              const list = hookData.doc.setFont('TH-Niramit', 'bold').setFontSize(fontBody.value).splitTextToSize(`${index + 1}) ${item.orderdetail_name}*${item.orderdetail_amount}  ${item.orderdetail_unit} (${item.orderdetail_direction || ''})`, 90)
              list.forEach(element => {
                hookData.doc.text(element, starterR + 3, totalR)
                totalR += +spacer
              })
            })
            hookData.doc.text(`${i18n.t('note')}: `, starterR, totalR)
            hookData.doc.line(starterR, totalR + 0.5, starterR + 13, totalR + 0.5)

            totalR += +spacer
            const comment = hookData.doc.setFont('TH-Niramit', 'bold').setFontSize(fontBody.value).splitTextToSize(data[a].order_comment
                .replaceAll('<p>', '')
                .replaceAll('<&nbsp;>', ' ')
                .replaceAll('<span>', '')
                .replaceAll('</span>', '')
                .replaceAll('</p>', '')
                .replaceAll('<br>', '\n')
                .replaceAll('</br>', '')
                .replaceAll('<li>', '- ')
                .replaceAll('</li>', '\n')
                .replaceAll('<ul>', '')
                .replaceAll('</ul>', '')
                .replaceAll('<ol>', '')
                .replaceAll('</ol>', ''),
              93)
            comment.forEach((element, index) => {
              hookData.doc.text(element, index == 0 ? starterL + 14 : starterL + 3, totalL)
              totalL += spacer
            })
            hookData.doc.text(`${i18n.t('doctor_inspector')}: ${data[a].user_fullname}`, starterR, totalR)
          },

        })
        doc.addPage(1)
      }

      doc.deletePage(data.length + countPage.value + 1)
      frame.value = doc.output('datauristring', { filename: 'hello world' })
    }
    return {
      frame,
    }
  },
}
</script>
